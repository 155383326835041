@value White, Tundora, Placeholder, CopperRose, CuttySark from '../StyleGuide/StyleConstants.module.css';

.page, .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page {
  margin: 30px 0;
  height: 612px; /* trying to match membership plans page for seamless transition */
}
.header {
  margin-bottom: 12px;
}

.divider {
  border-bottom-width: 3px;
  border-color: CopperRose;
  margin-bottom: 10px;
  max-width: 85%;
}

.text {
  font-size: 16px;
  margin-bottom: 32px;
  padding: 0 20px;
  text-align: center;
}

.form {
  width: 400px;
  max-width: 85%;
}

.formInner {
  width: 100%;
}

.error {
  width: 100%;
  max-width: 85%;
}

.inputs {
  width: 100%;
  background: White;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailContainer, .passwordContainer {
  width: calc(100% - 60px /* container padding */);
}

.firstName, .lastName {
  width: calc(100% - 60px /* container padding */ - 20px /* button padding */);
}

.firstName, .lastName {
  border: 0;
  border-radius: 0;
}

.firstName, .lastName {
  border-bottom: 1px solid Tundora;
}

.lastName {
  margin-top: 10px;
}

.firstName {
  margin-top: 30px;
}

.agreement {
  width: calc(100% - 60px /* container padding */);
  margin: 40px 0 30px;
  display: flex;
  align-items: center;
}

.agreementText {
  font-size: 10px;
  margin-left: 15px;
}

.agreementText a {
  color: CuttySark;
  text-decoration: none;
}
.agreementText a:hover {
  text-decoration: underline;
}

.submit {
  width: 136px;
  padding-left: 0;
  padding-right: 0;
  margin: 30px auto 0;
}

.birthPlanNote {
  align-self: baseline;
  margin-left: 30px;
  margin-top: 8px;
  font-size: 10px;
  font: 400 13.3333px Arial;
  color: grey;
  font-style: italic;
}