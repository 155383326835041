@value CopperRose, Placeholder from '../StyleGuide/StyleConstants.module.css';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 0;
  text-align: center;
}

.parenthetical {
  margin: 6px 0 20px;
  color: Placeholder;
}

.image {
  margin-top: 6px;
  height: 250px;
  width: 250px;
}

.link {
  margin: 30px 0 40px;
}