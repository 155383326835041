@value MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

.planCards {
  display: flex;
  justify-content: center;
  min-height: 300px;
  position: relative;
  align-items: center;
  padding-bottom: 60px;
}
.header {
  text-align: center;
  margin-top: 30px;
}
.plans.mobile .planCards {
  flex-direction: column;
  margin-top: 30px;
}
.plans.mobile .header {
  font-size: 30px;
}
.plans:not(.mobile) .planCards {
  flex-direction: row;
  max-width: 1170px;
  margin: 30px auto 0;
  justify-content: space-around;
}
