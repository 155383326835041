@value Tundora, MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  color: Tundora;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.divider {
  border-bottom: 2px solid Tundora;
}

.below {
  display: flex;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: left;
}

@media (max-width: MobileBreakpoint) {
  .page {
    padding: 30px 0;
  }
  .inner {
    width: calc(100% - 60px);
  }
  .below {
    flex-direction: column;
    align-items: center;
    padding: 10px 0 20px;
    font-size: 20px;
  }
  .image {
    width: 100%;
  }
  .appStore {
    margin: 50px 0 20px;
  }
}

@media (min-width: MobileBreakpoint) {
  .page {
    margin-top: 30px;
    padding-top: 40px;
    height: 400px;
  }

  .inner {
    max-width: 600px;
  }

  .below {
    margin-top: 20px;
    align-items: center;
  }

  .image {
    height: 200px;
  }

  .right {
    margin-left: 10px;
    font-size: 14px;
  }

  .appStore {
    margin-top: 60px;
  }

  .appStore > img {
    height: 40px;
  }
}