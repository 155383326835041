@value MobileBreakpoint, MobileNavHeight, DesktopNavHeight, Concrete from '../StyleGuide/StyleConstants.module.css';

.app {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contentContainer {
  border-width: 0 0 1px;
  border-color: Concrete;
  border-style: solid;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
}

.appFlow {
  height: 100%;
}

@media (max-width: MobileBreakpoint) {
  .contentContainer:not(.appFlow) {
    margin-top: MobileNavHeight;
  }
}
@media (min-width: MobileBreakpoint) {
  .contentContainer:not(.appFlow) {
    margin-top: DesktopNavHeight;
  }
}

.content {
  flex: 1;
}

.content > h1 {
  text-align: center;
}

.staticContentPage {
  padding: 20px 30px 30px;
}