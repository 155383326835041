@value BodyFont, AltFont from './StyleConstants.module.css';

.Header1,
.Header2,
.Header3,
.Header4,
.Header5,
.Header6,
.Header7,
.Header8,
.Header9,
.NavLink,
.FooterLink {
  font-family: AltFont;
}

.Body {
  font-family: BodyFont;
  font-weight: 400;
  font-size: 16px;
}

.Header1 {
  font-weight: 600;
  font-size: 90px;
}

.Header2 {
  font-weight: 600;
  font-size: 48px;
}

.Header3 {
  font-weight: 500;
  font-size: 40px;
}

.Header4 {
  font-weight: 300;
  font-size: 40px;
}

.Header5 {
  font-weight: 500;
  font-size: 32px;
}

.Header6 {
  font-weight: 300;
  font-size: 32px;
}

.Header7 {
  font-weight: 500;
  font-size: 24px;
}

.Header8 {
  font-weight: 300;
  font-size: 24px;
}

.Header9 {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.NavLink {
  font-weight: 500;
  font-size: 14px;
}

.FooterLink {
  font-weight: 500;
  font-size: 12px;
}
