@value Concrete, William, CopperRose, White, Placeholder, MobileBreakpoint, MidsizeBreakpoint, MidsizeWideBreakpoint from '../StyleGuide/StyleConstants.module.css';

@value BorderRadius: 4px;
@value Padding: 15px;

.plan {
  border-radius: BorderRadius;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 2px 4px 10px Placeholder;
  background: White;
}
.above {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
  justify-content: space-between;
}
.nameAndDiscount {
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}
.name {
  text-align: center;
  line-height: 1.2em;
  color: William;
}
.discountMsg {
  flex: 0;
  height: 50px;
  width: 100%;
  background: Concrete;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: BorderRadius BorderRadius 0 0;
  color: William;
}
.nameAndDiscount:not(.discountPresent) {
  padding-top: 10px;
}
.price {
  display: flex;
  text-align: center;
  justify-content: center;
  flex: 0;
}
.priceSpan {
  position: relative;
  margin: 0;
  width: fit-content;
}
.priceValue {
  margin: 0 !important;
  padding: 0 !important;
  width: fit-content;
  font-size: 48px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
.fullPrice {
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  color: Placeholder;
  text-decoration: line-through;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.descList {
  padding: 0 Padding;
  list-style: none;
}
.descList li {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.descList li img {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
.buyContainer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buy {
  padding: 0;
}
.buy:not(.disabled) {
  border: 2px solid CopperRose;
}
.plan:not(.callout) .buy:not(.disabled) {
  color: CopperRose;
  background: transparent;
}

.plan.mobile {
  height: 440px;
  width: 80%;
}
.plan.mobile:not(:first-of-type) {
  margin-top: 40px;
}
.plan.mobile .name {
  margin: 15px 35px 0;
  font-size: 24px;
}
.plan.mobile .fullPrice {
  right: calc(100% + 5px);
  top: 28px;
  font-size: 24px;
}
.plan.mobile .descList {
}
.plan.mobile .descList span {
  font-size: 13px;
}
.plan.mobile .descList li:not(:first-of-type) {
  margin-top: 10px;
}
.plan.mobile .buy {
  width: 80%;
  height: 38px;
}

.plan:not(.mobile) {
  height: 440px;
  width: 30%;
}
.plan:not(.mobile) .name {
  margin: 15px 45px 0;
}
.plan:not(.mobile) .buy {
  width: calc(100% - 46px);
  height: 38px;
}
.plan:not(.mobile) .fullPrice {
  right: calc(100% + 10px);
}
.plan:not(.mobile) .descList {
}
.plan:not(.mobile) .descList span {
  font-size: 14px;
}
.plan:not(.mobile) .descList li:not(:first-of-type) {
  margin-top: 12.5px;
}

@media (min-width: MobileBreakpoint) and (max-width: MidsizeBreakpoint) {
  .plan:not(.mobile) .name {
    font-size: 22px;
  }
  .plan:not(.mobile) .fullPrice {
    font-size: 22px;
    top: 28px;
    right: calc(100% + 5px);
  }
  .plan:not(.mobile) .descList span {
    font-size: 12px;
  }
}

@media (min-width: MidsizeBreakpoint) and (max-width: MidsizeWideBreakpoint) {
  .plan:not(.mobile) .fullPrice {
    font-size: 30px;
    top: 20px
  }
  .plan:not(.mobile) .descList span {
    font-size: 13px;
  }
}

@media (min-width: MidsizeWideBreakpoint) {
  .plan:not(.mobile) .fullPrice {
    font-size: 40px;
    top: 8px
  }
  .plan:not(.mobile) .descList span {
    font-size: 13px;
  }
}