@value CopperRose, Placeholder, Concrete, Gothic, William, White, MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

@value FormMargin: 10px;

.checkoutFormContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.orderSummaryContainer, .paymentInformationContainer {
  display: flex;
}

.orderSummaryContainer {
  background: linear-gradient(45deg, Gothic 0%, William 100%);
}

.paymentInformation {
  margin: 30px 20px;
  width: calc(100% - 60px);
}

.paymentInformation hr {
  border: 0;
  border-bottom: 1px solid black;
}

.header {
  margin: 10px 0;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.expAndCvc {
  display: flex;
}

.cardExpiry {
  flex: 1;
}

.cardExpiry {
  margin-right: FormMargin;
}

.cardCvc {
  flex: 1;
}

.cardNumber, .cardExpiry, .cardCvc {
  margin-top: FormMargin;
}

.tosNotice {
  margin: 14px 0;
  font-size: 10px;
}

.tosNotice a {
  text-decoration: none;
  color: Gothic;
}
.tosNotice a:hover {
  text-decoration: underline;
}

.submit {
  margin: 0 auto;
}

.cardError {
  margin-top: 20px;
}

@media (max-width: MobileBreakpoint) {
  .checkoutFormContainer {
    flex-direction: column;
  }

  .orderSummary .header, .paymentInformation .header {
    text-align: center;
  }
}

@media (min-width: MobileBreakpoint) {
  .orderSummaryContainer, .paymentInformationContainer {
    flex-direction: column;
    width: 50%;
    flex-shrink: 0;
  }

  .orderSummaryContainer {
    align-items: flex-end;
  }

  .orderSummary, .paymentInformation {
    max-width: 420px;
  }
}