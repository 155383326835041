@value Tundora, Concrete, Placeholder, White, MobileNavHeight, DesktopNavHeight, CopperRose, MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

.navbar {
  height: MobileNavHeight;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: White;
  z-index: 2;
  border-width: 0 0 1px;
  border-color: Concrete;
  border-style: solid;
}
.navLogoContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.navLogoLink {
  height: 100%;
}

@media (min-width: MobileBreakpoint) {
  .navbar {
    height: DesktopNavHeight;
    padding: 0 20px;
    justify-content: left;
  }
  .navLogoContainer {
    position: static;
    height: 40px;
    width: auto;
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
  }
}
