@value Tundora, MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  color: Tundora;
}
.divider {
  border-bottom: 2px solid Tundora;
}
.right > p { 
  font-size: 12px;
}

@media (max-width: MobileBreakpoint) {
  .page {
    height: 100%;
  }
  .image {
    margin: 20px 0 0;
    height: 80px;
  }
  .confirmation {
    max-width: 80%;
  }
  .header {
    font-size: 20px;
  }
  .next {
    margin-top: 20px;
    width: 50%;
  }
}

@media (min-width: MobileBreakpoint) {
  .page {
    margin-top: 30px;
    padding-top: 40px;
    height: 400px;
  }
  .image {
    height: 200px;
  }
}