@value CopperRose, Placeholder, Concrete, Gothic, William, White, MobileBreakpoint from '../StyleGuide/StyleConstants.module.css';

.orderSummary {
  margin: 30px 20px;
  width: calc(100% - 60px);
  color: White;
}
.orderSummary hr {
  border: 0;
  border-bottom: 1px solid White;
}
.header {
  margin: 10px 0;
}
.productInfo {
  display: flex;
  flex-direction: column;
  color: White;
  width: 100%;
  margin-top: 20px;
}
.productInfoRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.productInfoRow:not(:first-of-type) {
  margin-top: 20px;
}
.fullPrice {
  text-decoration: line-through;
}
.discountedPrice {
  margin-left: auto;
}
.badCoupon {
  margin-left: auto;
  font-size: 12px;
}
.total {
  margin-top: 20px;
}
.total span {
  font-weight: bold;
}

@media (max-width: MobileBreakpoint) {
  .header {
    text-align: center;
  }
  .promoSection {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .promoSection button {
    margin-left: 20px;
  }

  .productInfoRow {
    align-items: center;
  }

  .productInfoRow form {
    display: flex;
    height: fit-content;
    align-items: center; 
  }

  .addPromoCode {
    margin-top: 20px;
    color: White;
    font-family: 'Poppins';
    font-size: 14px;
  }
}

@media (min-width: MobileBreakpoint) {
  .orderSummary {
    max-width: 420px;
  }

  .promoSection {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .promoSection button {
    margin-left: 20px;
  }
}
