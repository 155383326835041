@value Placeholder from '../StyleGuide/StyleConstants.module.css';

.overlay {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 2;
  opacity: 0.6;
  flex-direction: column;
}

.children {
  min-width: 200px;
  text-align: center;
}

.loader svg {
  stroke: Placeholder;
}