@value Concrete, William, CopperRose, Tundora, Placeholder from '../StyleGuide/StyleConstants.module.css';

.Button, .LinkButton {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  line-height: 22px;
  border-radius: 22px;
  border: none;
  box-sizing: content-box;
}
.Button:not(:disabled){
  cursor: pointer;
}

/* size */
.sm {
  padding: 0.43em 2em;
}
.md {
  padding: 0.63em 3.1em;
}
.lg {
  height: 30px;
  font-size: 16px;
  padding: 0.6em 3.4em;
  border-radius: 25px;
}

/* colorVariant */

.Button.std:disabled, .LinkButton.std.disabled {
  background: Placeholder;
  opacity: 1;
}
.Button.cta:disabled, .LinkButton.cta.disabled {
  opacity: 0.5;
  cursor: default;
}
.Button.std, .LinkButton.std {
  background: CopperRose;
}
.Button.cta, .LinkButton.cta {
  background: William;
}

/* misc */

.LinkButton.disabled {
  pointer-events: none;
}
.Button {
  outline-color: Tundora;
}
.LinkButton {
  text-decoration: none;
}
.ButtonLink {
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
}