.pageInvalid {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageLoading {

}

.page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page form > button {
  margin-top: 30px;
}

.pageSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}