.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 10px;
}
.page form > div {
  margin-top: 10px;
  width: 300px;
}
.page form > button {
  margin: 30px 0px 0px 0px;
}
.error {
  margin: 20px 0 0;
}
