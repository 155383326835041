@value Concrete, White, Tundora, Placeholder, CopperRose, OrientalPink from '../StyleGuide/StyleConstants.module.css';

.input, .select, .textarea {
  border: 1px solid Concrete;
  padding: 10px;
  border-radius: 7px;
  font-size: 14px;
}
.selectContainer {
  position: relative;
}
.select {
  appearance: none;
  width: 100%;
  padding-right: 30px;
}
.selectIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 3px;
  top: calc(50% - (18px / 2));
  pointer-events: none;
}
.textarea {
  font-family: Arial;
}
.passwordVisibilityButton {
  width: 20px;
  height: 20px;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}
.passwordContainer, .emailContainer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid Tundora;
}
.password, .iconEmailInput {
  width: calc(100% - 20px /* input padding */);
}
.passwordIcon, .passwordVisibilityIcon, .emailIcon {
  fill: Placeholder;
  width: 20px;
  align-self: flex-end;
}
.emailIcon {
  margin-bottom: 8px;
}
.passwordIcon {
  margin-bottom: 6px;
}
.password, .iconEmailInput {
  border: 0;
  border-radius: 0;
}
.password {
  margin-top: 10px;
}
.checkboxContainer {
  display: flex;
  align-items: center;
}
.checkbox {
  margin-right: 10px;
  height: 22px;
  width: 22px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid CopperRose;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
.checkbox:checked {
    border: 1px solid OrientalPink;
    background: OrientalPink url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 50% 50% no-repeat;
}
.checkbox:active {
    border: 2px solid CopperRose;
}