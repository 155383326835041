@value MobileBreakpoint, Placeholder, Concrete, White from '../StyleGuide/StyleConstants.module.css';

.faqs {
  margin: 50px auto;
  max-width: 800px;
  padding: 0 40px;
}

.header {
  text-align: left !important;
  margin: 0;
}

.faq {
  border: 2px solid Concrete;
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  background: White;
  outline: Concrete;
  cursor: pointer;
  overflow: hidden;
  padding: 0 10px;
}

.faq:first-of-type {
  margin-top: 10px;
}

.topRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
}

.question {
  margin-left: 8px;
  text-align: left;
}

.answer {
  text-align: left;
  margin-left: 8px;
  line-height: 1.6;
}

.expandIcon {
  user-select: none;
  flex-shrink: 0;
  margin-left: 16px;
}