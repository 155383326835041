.page {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 40px;
  height: 100%;
  overflow: auto;
}
.section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section:not(:first-of-type) {
  margin-top: 30px;
}
.title {
  width: 130px;
}
.items {
  max-width: 800px;
}
.items > * {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}
.swatchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
}
.swatch {
  height: 60px;
  width: 60px;
}
.label {
  font-weight: bold;
}
.hex {
  font-family: monospace;
}
.label, .hex {
  margin-top: 5px;
}
.buttonStack {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonStack > *:not(:first-of-type) {
  margin-top: 10px;
}
.breakpointContainer {
  display: flex;
  align-items: center;
}
.breakpointLine {
  flex-shrink: 0;
  height: 1px;
  background: black;
}
.breakpointLabel {
  margin-left: 5px;
  font-size: 14px;
  font-family: monospace;
}